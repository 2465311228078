$spacer: 1rem;

.ms-1 {
  margin-left: ($spacer * 0.25) !important;
}

.ms-2 {
  margin-left: ($spacer * 0.5) !important;
}

.ms-3 {
  margin-left: $spacer !important;
}

.me-1 {
  margin-right: ($spacer * 0.25) !important;
}

.me-2 {
  margin-right: ($spacer * 0.5) !important;
}

.me-3 {
  margin-right: $spacer !important;
}

.mb-1 {
  margin-bottom: ($spacer * 0.25) !important;
}

.mb-2 {
  margin-bottom: ($spacer * 0.5) !important;
}

.mb-3 {
  margin-bottom: $spacer !important;
}

.ps-1 {
  padding-left: ($spacer * 0.25) !important;
}

.ps-2 {
  padding-left: ($spacer * 0.5) !important;
}

.ps-3 {
  padding-left: $spacer !important;
}

.pe-1 {
  padding-right: ($spacer * 0.25) !important;
}

.pe-2 {
  padding-right: ($spacer * 0.5) !important;
}

.pe-3 {
  padding-right: $spacer !important;
}

.pb-1 {
  padding-bottom: ($spacer * 0.25) !important;
}

.pb-2 {
  padding-bottom: ($spacer * 0.5) !important;
}

.pb-3 {
  padding-bottom: $spacer !important;
}

.px-1 {
  padding-right: ($spacer * 0.25) !important;
  padding-left: ($spacer * 0.25) !important;
}

.px-2 {
  padding-right: ($spacer * 0.5) !important;
  padding-left: ($spacer * 0.5) !important;
}

.px-3 {
  padding-right: $spacer !important;
  padding-left: $spacer !important;
}
