#toast-container.toast-top-center .ngx-toastr,
#toast-container.toast-bottom-center .ngx-toastr {
  width: 90%;
}

@media screen and (min-width: 1200px) {
  #toast-container.toast-top-center .ngx-toastr,
  #toast-container.toast-bottom-center .ngx-toastr {
    width: 1000px;
  }
}
