.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-wrap-nowrap {
  text-wrap: nowrap;
}

.color-inherit {
  color: inherit;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.justify-content-end {
  display: flex;
  justify-content: end;
}

.justify-content-start {
  display: flex;
  justify-content: start;
  padding-top: 20px;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.text-align-center {
  text-align: center;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.d-flex {
  display: flex;
}

.overflow-auto {
  overflow: auto;
}
