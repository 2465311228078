.tooltip .tooltip-inner {
  background-color: var(--bg-tootip-color);
}

.tooltip-strong.show {
  opacity: 0.95 !important;
}

.tooltip .tooltip-inner,
.tooltip-strong .tooltip-inner {
  border: 1px solid var(--im-inactive-color);
  max-width: 50vw;
  white-space: pre-line;
  font-size: small;
  pointer-events: none;
}

.left-align .tooltip-inner {
  text-align: left;
}
