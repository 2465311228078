.dts-no-select {
  user-select: none;
}

.dts-select-box {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid currentColor;
  contain: layout;
  transform: translateZ(0);
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 1;
}
.dts-select-box.dts-removing {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.8);
}

.dts-select-container:not(.dts-custom) .dts-select-item.selected {
  opacity: 1 !important;
}
