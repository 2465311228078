$headerHeight: 4rem;
$headerLogo: 3rem;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    //  font-size: 75%; //1rem = 12, 12/16

    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  }
}

body {
  // font-family: "Inter", sans-serif;
  font-family: sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  color: var(--im-text-color);
  background-color: var(--bg-back-color);
  padding-top: 6rem;
}

html,
body {
  height: 100%;
}
