.im-btn,
.im-btn-toggle {
  border: none;
  color: var(--im-text-color);
  background-color: var(--im-inactive-color);

  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.im-btn:hover,
.im-btn-toggle:hover,
.im-btn-toggle.active:active {
  background-color: rgb(33, 33, 33);
  color: rgb(194, 194, 194);
}

.im-btn:active,
.im-btn-toggle:active,
.im-btn-toggle.active {
  background-color: rgb(194, 194, 194);
  color: rgb(33, 33, 33);
}

a.im-btn {
  padding: 0.25em;
  text-align: center;
}

a:focus {
  outline: 1px solid var(--im-active-color);
}

a:hover {
  color: var(--im-text-color);
}

a img:hover {
  outline: 1px solid var(--im-active-color);
}

.im-btn:focus,
.im-btn-toggle:focus,
button:focus {
  outline: 1px solid var(--im-active-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 3px var(--im-active-color-translucent);
}

.im-btn-toggle.active:hover {
  background-color: var(--im-active-color);
}

.btn-outline-primary {
  color: var(--im-text-color);
}
