.grid-columns-f-f {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-columns-2f-f {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-columns-f-2f {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-columns-f-min {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-columns-min-f {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-columns-f-f-f {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-columns-min-f-f {
  display: grid;
  grid-template-columns: min-content 1fr 1fr;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-columns-f-min-f {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-columns-f-f-min {
  display: grid;
  grid-template-columns: 1fr 1fr min-content;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-columns-min-min-f {
  display: grid;
  grid-template-columns: min-content min-content 1fr;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-columns-f-min-min {
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-columns-min-f-min {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: 100%;
  width: 100%;
}

.grid-rows-f-f {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-2f-f {
  display: grid;
  grid-template-rows: 2fr 1fr;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-f-2f {
  display: grid;
  grid-template-rows: 1fr 2fr;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-f-min {
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-0f-min {
  display: grid;
  grid-template-rows: minmax(0, 1fr) min-content;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-min-0f {
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-min-f {
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-f-f-f {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-min-f-f {
  display: grid;
  grid-template-rows: min-content 1fr 1fr;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-f-min-f {
  display: grid;
  grid-template-rows: 1fr min-content 1fr;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-f-f-min {
  display: grid;
  grid-template-rows: 1fr 1fr min-content;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-min-min-f {
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-f-min-min {
  display: grid;
  grid-template-rows: 1fr min-content min-content;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-min-f-min {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-min-0f-min {
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr) min-content;
  grid-template-columns: 100%;
  height: 100%;
}

.grid-rows-min-min-0f {
  display: grid;
  grid-template-rows: min-content min-content minmax(0, 1fr);
  grid-template-columns: 100%;
  height: 100%;
}
