.im-field {
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  background-color: var(--bg-panel);
  color: var(--im-text-color);
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  min-height: 1.7rem;
}

.im-field:focus {
  outline-style: solid;
  outline-width: 1px;
  outline-color: var(--im-active-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 3px var(--im-active-color-translucent);

  border-color: rgba(0, 0, 0, 0.2);
  background-color: var(--bg-panel);
  color: var(--im-text-color);
}

input[type='number'] {
  width: 80px;
}

.border-default,
.border-default-pristine {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.border-pristine,
.border-changed {
  border-color: #aaa !important;
}

.border-dirty {
  border-color: #aaa04d !important;
}

.border-default,
.border-default-pristine,
.border-pristine,
.border-dirty,
.border-changed {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
}
