// COLORS
$primaryColor: #00adee;

$color: #f0f0f0;
$backgroundColor: #172039;
$nav-bar-height: 10rem;
$green: #00ff00;
$red: red;
$gray: gray;
$blue: #3a7bcf;
$bg-back-color: #172039;
$nav-bar-height: 30px;
$bg-back-colorrr: #b7c0da;
$bg-tootip-color: #0f1424;
$bg-translucent: #00000000;
$bg-compartment: #090c14;
$bg-copy-button: #00adee1a;
$border-copy-button: #00adee80;
$info-color: #00adee;
$blue-title-button: #00adee;
$abnormal-red: #be1e2d;
$normal-green: #8cc63f;
$doubt-orange: #f7931e;
$not-applicable-gray: gray;
$global-padding: 30px;
$keros-result-page-padding: 180px;
$bg-nav-color: #101425;
$bg-panel-color: #172039;
$bg-panel-color-translucent: #17203980;
$im-active-color: #223055;
$im-active-color-translucent: #22305580;
$im-inactive-color: #172039;
$im-inactive-color-translucent: #17203980;

$bg-panel-highlight-color: #223055;
$im-scrollbar-bg-color: #172039;
$im-scrollbar-thumb-color: #424153;

// used un sevral files, to factorize later
:root {
  --bg-back-color: #172039;
  --nav-bar-height: 30px;
  --bg-tootip-color: #0f1424;
  --blue-title-button: #00adee;
  --bg-panel-color: #172039;
  --im-text-color: #f0f0f0;
}

// FONT
$default-font-size: 1.6rem;
