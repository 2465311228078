$im-scrollbar-thumb-color: #424153;
$im-scrollbar-bg-color: rgba(23, 32, 57, 1);
.overflowing::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 12px;
  background-color: rgb(21 47 75);
  border-radius: 12px;
  cursor: n-resize;
}

.overflowing::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 173, 238, 0.6);
  border-radius: 25px;
  cursor: n-resize;
}

.overflowing {
  scrollbar-width: 5px;
  scrollbar-color: $im-scrollbar-thumb-color $im-scrollbar-bg-color;
}
