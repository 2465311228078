table {
  border-collapse: collapse;
}

td {
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 1px;
  border-style: solid;
  padding: 0.5em;
}

tr {
  background-color: rgba(80, 80, 80, 1);
}

tr:hover {
  background-color: rgba(60, 60, 60, 1);
}

th {
  background-color: rgba(50, 50, 50, 1);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  padding: 0.5em;
}

th:hover {
  background-color: rgba(30, 30, 30, 1);
}
