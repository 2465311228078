$nav-bar-height: var(--nav-bar-height);
$main-menu-width: 100px;

ul,
ol,
li {
  list-style-type: none;
}

button,
a {
  cursor: pointer;
  text-decoration: none;
}

.flex-row {
  @include flex-row;
}

.flex-column {
  @include flex-column;
}

.pointer {
  cursor: pointer;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.bg-nav {
  background-color: var(--bg-nav-color);
}

.bg-front {
  background-color: var(--bg-panel-color);
}

.popup-backdrop {
  background-color: var(--bg-back-color);
}

.col-resize {
  cursor: col-resize;
}

.main-login {
  display: grid;
  grid-template-rows: 0.4fr min-content 1fr;
  grid-template-columns: 1fr min-content 1fr;
  grid-template-areas:
    '. . .'
    '. login-form .'
    '. . .';
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.large-text {
  font-size: 1.1rem;
}

.larger-text,
.extra-large-text {
  font-size: 1.2rem;
}

.largest-text,
.extra-extra-large-text {
  font-size: 1.3rem;
}

.small-text {
  font-size: 0.9rem;
}

.smaller-text,
.extra-small-text {
  font-size: 0.8rem;
}

.smallest-text,
.extra-extra-small-text {
  font-size: 0.7rem;
}

.overflowing {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
}

.main-menu {
  width: $main-menu-width;
}

.light-highlight:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-highlight:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.highlight:hover {
  filter: brightness(1.2);
}
