:root {
  --color-selected-viewport: rgb(194, 194, 194);
  --color-not-selected-viewport: rgb(20, 20, 20);
  --color-viewer-panel: var(--bg-panel-color);
  --color-btn-hover: rgb(8, 8, 8);
}

.viewer-panel {
  background-color: var(--color-viewer-panel);
}
