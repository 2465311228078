// SIZE
.u-width-100 {
  width: 100% !important;
}

.u-width-90 {
  width: 90% !important;
}

.u-width-80 {
  width: 80% !important;
}

.u-width-70 {
  width: 70% !important;
}

.u-width-60 {
  width: 60% !important;
}

.u-width-50 {
  width: 50% !important;
}

.u-width-40 {
  width: 40% !important;
}

.u-width-30 {
  width: 30% !important;
}

.u-width-20 {
  width: 20% !important;
}

.u-width-10 {
  width: 10% !important;
}

.u-height-vh1 {
  height: 1vh !important;
}

.u-height-max {
  height: 100% !important;
  min-height: 100% !important;
}

.u-width-max {
  width: 100% !important;
  min-width: 100% !important;
}

.u-height-100 {
  height: 100% !important;
}

.u-height-90 {
  height: 90% !important;
}

.u-height-80 {
  height: 80% !important;
}

.u-height-70 {
  height: 70% !important;
}

.u-height-60 {
  height: 60% !important;
}

.u-height-50 {
  height: 50% !important;
}

.u-height-40 {
  height: 40% !important;
}

.u-height-30 {
  height: 30% !important;
}

.u-height-20 {
  height: 20% !important;
}

.u-height-10 {
  height: 10% !important;
}

.width-min-content {
  width: min-content !important;
}
