.light-backdrop {
  background-color: white;
  &.show {
    opacity: 0.3;
  }
}

.light-modal .modal-content {
  background-color: var(--bg-panel-color);
  color: var(--info-color);
  padding: 2rem;
}

.light-modal .modal-dialog {
  max-width: 400px;
}

.modal-md .modal-sm {
  max-width: 40%;
  border-color: #3495f0;
}
