.icon svg,
.icon img,
.icon div {
  border-width: 1px;
  border-color: rgb(25, 25, 25);
  border-style: solid;
  border-radius: 3px;
}

.icon svg,
.icon img,
.icon .icon-text {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.icon svg {
  fill: rgb(25, 25, 25);
  padding: 4px;

  width: 30px;
  height: 30px;
}

.icon img {
  padding: 4px;

  width: 30px;
  height: 30px;
}

.icon div {
  color: rgb(25, 25, 25);
}

.icon.hovered div,
.icon.hovered svg {
  border-color: rgb(194, 194, 194);
  fill: rgb(194, 194, 194);
  color: rgb(194, 194, 194);
}
.icon.hovered img {
  filter: invert(0.8);
}

.icon:active svg,
.icon.hovered:active svg,
.icon.selected svg,
.icon.selected:hover svg,
.icon:active div,
.icon.hovered:active div,
.icon.selected div,
.icon.selected:hover div,
.icon:active img,
.icon.hovered:active img,
.icon.selected img,
.icon.selected:hover img {
  border-color: rgb(33, 33, 33);
  color: rgb(33, 33, 33);
  fill: rgb(33, 33, 33);
}

.icon:active img,
.icon.hovered:active img,
.icon.selected img,
.icon.selected:hover img {
  filter: none;
}
