.spinner {
  margin: auto;
  border: 0.25rem solid #ddd;
  border-top: 0.25rem solid #333;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  animation: spin 2s linear infinite;
}

.spinner-container {
  display: flex;
  margin: auto;
  width: 20px;
  height: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
