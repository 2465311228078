.dark {
  --im-graph-text: #959295;
  .ngx-charts {
    text {
      fill: var(--im-graph-text);
    }

    .tooltip-anchor {
      fill: var(--im-text-color);
    }

    .gridline-path {
      stroke: var(--im-graph-text);
    }

    .refline-path {
      stroke: var(--im-text-color);
    }

    .reference-area {
      fill: var(--im-text-color);
    }

    .force-directed-graph {
      .edge {
        stroke: var(--im-text-color);
      }
    }

    .number-card {
      p {
        color: var(--im-text-color);
      }
    }

    .gauge {
      .background-arc {
        path {
          fill: var(--im-inactive-color);
        }
      }

      .gauge-tick {
        path {
          stroke: var(--im-text-color);
        }
        text {
          fill: var(--im-text-color);
        }
      }
    }

    .linear-gauge {
      .background-bar {
        path {
          fill: var(--im-inactive-color);
        }
      }

      .units {
        fill: var(--im-text-color);
      }
    }

    .timeline {
      .brush-background {
        fill: rgba(255, 255, 255, 0.05);
      }

      .brush {
        .selection {
          fill: rgba(255, 255, 255, 0.1);
          stroke: var(--im-text-color);
        }
      }
    }

    .polar-chart .polar-chart-background {
      fill: var(--bg-back-color);
    }
  }

  .chart-legend {
    .legend-labels {
      background: none !important;
    }

    .legend-item {
      &:hover {
        color: var(--im-text-color);
      }
    }

    .legend-label {
      &:hover {
        color: var(--im-text-color) !important;
      }

      .active {
        .legend-label-text {
          color: var(--im-text-color) !important;
        }
      }
    }
  }

  .advanced-pie-legend {
    color: var(--im-text-color);

    .legend-item {
      &:hover {
        color: var(--im-text-color) !important;
      }
    }
  }

  .number-card .number-card-label {
    font-size: 0.8em;
    color: var(--im-text-color);
  }
}
