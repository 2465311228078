.im-graph {
  position: absolute;
}

.im-graph text {
  color: var(--im-text-color);
}

.im-graph .foreground {
  fill: none;
  stroke-width: 1.5;
}

.im-graph .axis text {
  user-select: none;
}

.im-graph .axis text.selected {
  color: rgb(255, 127, 14);
}

.im-graph .axis text.clicked {
  color: rgb(255, 127, 14);
}

.im-graph .label {
  text-anchor: middle;
  cursor: pointer;
  fill: var(--im-text-color);
}

.im-graph .label.selected {
  fill: rgb(255, 127, 14);
}

.im-graph .text-extent {
  fill: var(--im-text-color);
  font-size: 10px;
}

.im-graph .text-na {
  fill: var(--im-text-color);
  font-size: 10px;
  text-anchor: left;
}

.im-graph .line-na {
  stroke: var(--im-text-color);
  stroke-dasharray: 3 3;
}

.im-graph .selector-na {
  cursor: pointer;
  fill: var(--im-text-color);
  fill-opacity: 0.5;
}

.im-graph .bin-text {
  fill: #999999;
  font-size: 10px;
}

// fix bug of ngx-charts with horizontal legends
.horizontal-custom-legends .chart-legend .legend-labels {
  width: 105% !important;
}
