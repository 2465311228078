.tool-bar-icon.hovered {
  background-color: rgb(83, 83, 83);
}

.tool-bar-icon.hovered:hover {
  background-color: rgb(33, 33, 33);
}

.tool-bar-icon:active,
.tool-bar-icon.selected,
.tool-bar-icon.selected:hover,
.tool-bar-icon.hovered:active,
.tool-bar-icon.hovered.selected,
.tool-bar-icon.hovered.selected:hover {
  background-color: rgb(194, 194, 194);
}

.tool-bar-icon {
  border-radius: 3px;
}
